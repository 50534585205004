import { Link, useParams } from "react-router-dom";
import FilesDiff from "./FilesDiff";
import ReadOnlyFiles from "src/coursepart/ReadOnly/File/ReadOnlyFiles";
import { useI18n } from "src/utils/lni18n";
import { useMemo } from "react";
import { onePartatom } from "src/coursepart/CoursePartService";
import { useAtom } from "jotai";
import ChangedColorLegend, { ChangedColorLegendWrap } from "src/components/ChangedColorLegend";


const FilesDiffMain = () => {
    const { partId } = useParams();
    const { languageService: t } = useI18n();
    const partAtom = useMemo(() => onePartatom(partId!), [partId]);
    const [partData] = useAtom(partAtom);
    const fileDiffs = partData?.DiffFiles;

    if (!fileDiffs) return null;

    return (
        <>


            <div className="d-flex">
                <Link to={"../files"} className='ml-auto btn btn-inverse btn-small mb-4' >{`${t.getText("close")} ${t.getText("differences")}`}</Link>
            </div>


            <div className="row">

                <div className="col-md-6">
                    <ChangedColorLegend />
                    <h4 className="mb-2">{t.getText("edited")}</h4>

                    <FilesDiff fileGroups={fileDiffs!.Edited} />
                </div>

                <div className="col-md-6">
                    <ChangedColorLegendWrap></ChangedColorLegendWrap>
                    <h4 className="mb-2">{t.getText("published")}</h4>

                    <FilesDiff fileGroups={fileDiffs!.Published} />
                </div>
            </div >
        </>
    )
}

export default FilesDiffMain;
