import classes from '../files.module.scss';
import { useI18n } from 'src/utils/lni18n';
import { FileModelDiff } from "../../domain/FilesGroupModel";
import FileIcon from "src/components/FileIcons/FileIcon";
import LnIcon from 'src/components/LnIcon';
import { API_URL } from 'src/utils/constants';
import GetStateClass from "src/utils/StateColors";
import DOMPurify from 'dompurify';

const FileCardDiff = (props: { partId: string, file: FileModelDiff }) => {

    const { languageService: t } = useI18n();
    const { file, partId } = props;

    if (!file) {
        return null;
    }

    const url = `${API_URL}/author/coursepart/${partId}/file/${file.Id}/download?name=${encodeURIComponent(file.FileName)}`

    let bgstate = GetStateClass( file.State );

    return <div key={file.Id} className={`d-flex py-2 mx-4 position-relative border-top-but-first`}>
        <div className={`cardLeftIndicator ${bgstate}`}></div>
        <div className='mr-3'>
            <FileIcon className={[ classes['file-icon']].join(' ')} name={(file.FileName || '').split('.').pop()} />
        </div>

        <div className={classes.lineClamp} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( file.Description) }}>
        </div>

        <div className='px-3'>
            <div className={classes.fileInfo}>{file.FileName}</div>
            <span >{t.getMediumDateString(file.LastModified)}</span>
        </div>

        <div className='ml-auto'>
            <a target="_blank" rel="noopener noreferrer nofollow" title={t.getText('download')} className="" href={url}>
                <LnIcon name='download2' className='icon-small' />
            </a>
        </div>
    </div>

}

export default FileCardDiff;
