import { useEffect, useRef, useState } from "react";
import { useDialog } from "src/components/Modalservice/Dialogservice";
import { useAtom } from "jotai";
import screenAudioEditService, { currentWaveDataAtom } from "../ScreenAudioEditService";
import classes from '../player.module.scss';
import LnIcon from "src/components/LnIcon";
import { useI18n } from "src/utils/lni18n";
import { ConfirmationButtons, ConfirmationDialog, ConfirmationOptions } from "src/components/Modalservice/ConfirmationDialog";
import { useNavigate, useParams } from "react-router-dom";
import { DropdownMenu, DropdownToggle, Progress, UncontrolledDropdown } from "reactstrap";
import { AlertDialog, AlertOptions } from "src/components/Modalservice/AlertDialog";
import { CompleteMultipartUploadCommandOutput } from "@aws-sdk/client-s3";
import { RecorderDialog } from "../../AudioRecorder/RecorderDialog";
import recorderService from "../../AudioRecorder/Recoderservice";
import FileSaverDialog from "src/components/FileUpload/FileSaverDialog";
import { usePartPath } from "src/utils/usePartPath";


const WaveEditorControls = (props: { children?: any, className?: string }) => {

    const { languageService: t } = useI18n();

    const partPath = usePartPath();

    const [screendata] = useAtom(currentWaveDataAtom);
    const dialogPortal = useDialog();
    const navigate = useNavigate();
    const { partId, lectureId, num, courseId, themeId } = useParams();
    const { isPlaying, isDirty, haveSelection, canRedo, canUndo,
        hasStereo } = screendata;


    const startRecord = () => {

        const mono = screenAudioEditService.waves.length === 1;

        recorderService.initializeAudio(mono);
        var p = dialogPortal({
            factory: (onSubmit, onCancel) => {

                return <RecorderDialog onCancel={onCancel} onSubmit={onSubmit} />
            },
            size: "recorder"
        });
        p.then(result => {
            if (result && recorderService.recordedData) {
                screenAudioEditService.insertAudio(recorderService.getDataAsAudioSequences());
            }
        }).finally(() => {
            recorderService.Reset();
        });
    }

    const quitEdit = () => {

        if (isDirty) {


            var p = dialogPortal({
                factory: (onSubmit, onCancel) => {
                    const dprops: ConfirmationOptions = {
                        className: "",
                        title: t.getText("save.before.close.without.question"),
                        message: <div className="preserve-white" >{t.getText("save.before.close")}</div>,
                        languageService: t,
                        show: true,
                        onClose: onCancel,
                        onSubmit: onSubmit,
                        buttons: ConfirmationButtons.YesNo
                    }
                    return <ConfirmationDialog {...dprops} />
                },
                size: "md"
            });

            p.then((answ: boolean) => {
                if (answ) {

                    window.setTimeout(() => {
                        const saver = saveWave();
                        if (saver) {
                            saver.then(() => {
                                backToScreen();
                            }).catch(error => {

                            });
                        }
                    }, 100);
                }
                else {
                    backToScreen();
                }
            }).catch(() => { });
        }
        else {
            backToScreen();
        }

    }


    const saveWave = () => {
        const saveData = screenAudioEditService.saveAudio();
        if (!saveData) {
            dialogPortal({
                factory: (onSubmit, onCancel) => {
                    const dprops: AlertOptions = {
                        className: "",
                        title: t.getText("error"),
                        message: <div className="preserve-white" >{t.getText("generalerror")}. No data to save</div>,
                        languageService: t,
                        onSubmit: onSubmit
                    }
                    return <AlertDialog {...dprops} />
                },
                size: "md"
            });

            return undefined;
        }

        const promise = saveData.promise.then((d: CompleteMultipartUploadCommandOutput) => {
            return d;
        }).catch(e => {
            console.error(e);
        });

        dialogPortal({
            factory: (onSubmit, onCancel) => {
                return <FileSaverDialog onCancel={onCancel} />
            },
            size: "md"
        }).catch(() => {
            saveData.killer && saveData.killer();
        });

        return promise;
    }

    const backToScreen = () => {
        screenAudioEditService.playStop();
        screenAudioEditService.resetData();
        navigate(`./../../`, { state: { nostart: true } });

    }

    const handlePlayPauseClick = (all: boolean) => {
        screenAudioEditService.playToggle(all);
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyEvent, true);
        window.addEventListener("copy", copy, true);
        window.addEventListener("paste", paste, true);
        window.addEventListener("cut", cut, true);

        return () => {
            window.removeEventListener("keydown", handleKeyEvent, true);
            window.removeEventListener("copy", copy, true);
            window.removeEventListener("paste", paste, true);
            window.removeEventListener("cut", cut, true);
        }
    }, [])

    const handleKeyEvent = (e: KeyboardEvent) => {

        switch (e.key) {
            case " ": // space
                screenAudioEditService.playToggle(e.ctrlKey);
                break;

            case "a":
                if (e.ctrlKey) {
                    screenAudioEditService.selectAll();
                    e.preventDefault()
                }
                break;

            case "ArrowRight":
                screenAudioEditService.selectToTheRight(e.ctrlKey, e.shiftKey);
                break;

            case "ArrowLeft":
                screenAudioEditService.selectToTheLeft(e.ctrlKey, e.shiftKey);
                break;

            case "y":
                if (e.ctrlKey) {
                    screenAudioEditService.redo();
                }
                break;

            case "z":
                if (e.ctrlKey) {
                    screenAudioEditService.undo();
                }
                break;

            case "Backspace":
            case "Delete":
                screenAudioEditService.del();
                break;
            // case "Escape":
            //     quitButton.current && quitButton.current.click();
            //     break;
        }
    }

    const cut = () => {
        screenAudioEditService.cut();
    }

    const copy = () => {
        screenAudioEditService.copy();
    }
    const paste = () => {
        screenAudioEditService.paste();
    }

    return <>

        <div className={`${classes.buttonsbar} ${props.className} align-self-end  mx-auto my-2 pb-1 pl-3 pr-3 d-flex align-items-center`}>
            <div className="d-flex align-items-center">
                <button title={t.getText("close")} className="btn btn-link mr-3" onClick={() => quitEdit()}>
                    <LnIcon name="off" />
                </button>
                <div className="d-flex">
                    <button title={t.getText("play") + " (Space)"} className={classes.roundButton + " btn btn-link d-flex align-items-center mr-2"} onClick={() => handlePlayPauseClick(false)}>
                        {isPlaying ? <LnIcon name="pause" /> : <LnIcon name="play" />}
                    </button>
                    <button title={t.getText("play.all") + " (Ctrl + Space)"} className={`${classes.roundButton} ${classes.blueButton} btn btn-link d-flex align-items-center`} onClick={() => handlePlayPauseClick(true)}>
                        {isPlaying ? <LnIcon name="pause" /> : <LnIcon name="play" />}
                    </button>

                </div>

                <button title={t.getText("zoom100")} className="btn btn-link ml-3" onClick={(e) => { screenAudioEditService.zoomToFit() }}>
                    <LnIcon className="filter-white" name="zoom-out" />
                </button>

                <button disabled={!haveSelection} title={t.getText("zoomin")} className="btn btn-link" onClick={(e) => { screenAudioEditService.zoomIntoSelection() }}>
                    <LnIcon className="filter-white" name="zoom-in" />
                </button>

                <button disabled={!haveSelection} title={t.getText("hivolume")} className="btn btn-link ml-3" onClick={(e) => { screenAudioEditService.filterGain(+3) }}>
                    <LnIcon className="filter-white" name="volume-plus" />
                </button>
                <button disabled={!haveSelection} title={t.getText("lovolume")} className="btn btn-link" onClick={(e) => { screenAudioEditService.filterGain(-3) }}>
                    <LnIcon className="filter-white" name="volume-minus" />
                </button>
                <button title={t.getText("normalize")} className="btn btn-link " onClick={(e) => { screenAudioEditService.filterNormalize() }}>
                    <LnIcon name="volume" />
                </button>

                <button disabled={!haveSelection} title={t.getText("fadein")} className="btn btn-link ml-3" onClick={(e) => { screenAudioEditService.filterFadeIn() }}>
                    <LnIcon className="filter-white" name="arrow-up-right" />
                </button>
                <button disabled={!haveSelection} title={t.getText("fadeout")} className="btn btn-link" onClick={(e) => { screenAudioEditService.filterFadeOut() }}>
                    <LnIcon className="filter-white" name="arrow-down-right" />
                </button>

                <button disabled={!canUndo} title={t.getText("undo")} className="btn btn-link ml-3" onClick={(e) => { screenAudioEditService.undo() }}>
                    <LnIcon className="filter-white" name="undo" />
                </button>

                <button disabled={!canRedo} title={t.getText("redo")} className="btn btn-link" onClick={(e) => { screenAudioEditService.redo() }}>
                    <LnIcon className="filter-white" name="redo" />
                </button>

                {hasStereo &&
                    <button title={t.getText("merge.to.mono")} className="btn btn-link ml-3" onClick={(e) => { screenAudioEditService.MergeToMono() }}>
                        <LnIcon className="filter-white" name="share" />
                    </button>
                }

                <button disabled={!isDirty} title={t.getText("save.short")} className="btn btn-link mx-3" onClick={(e) => { saveWave() }}>
                    <LnIcon className="filter-white" name="download2" />
                </button>

                <button title={t.getText("record")} className="btn btn-link mx-3" onClick={startRecord}>
                    <LnIcon className="filter-white" name="mic" />
                </button>

                <UncontrolledDropdown onClick={() => { }}>
                    <DropdownToggle tag="button" className="btn btn-link mr-xs-2">
                        <LnIcon name="minus" className="filter-white" title={t.getText("insert.silence")} />
                    </DropdownToggle>


                    <DropdownMenu className="w-auto" >
                        <div className={"d-flex flex-column justify-content-right "}>
                            <button onClick={(e) => screenAudioEditService.insertSilence(0.25)} className={"btn btn-link  " + classes.speeds} >
                                <span>0.25 s</span>
                            </button>

                            <button onClick={(e) => screenAudioEditService.insertSilence(0.5)} className={"btn btn-link " + classes.speeds} >
                                <span>0.50 s</span>
                            </button>
                            <button onClick={(e) => screenAudioEditService.insertSilence(1)} className={"btn btn-link " + classes.speeds} >
                                <span>1.00 s</span>
                            </button>
                            <button onClick={(e) => screenAudioEditService.insertSilence(2)} className={"btn btn-link " + classes.speeds} >
                                <span>2.00 s</span>
                            </button>
                            <button onClick={(e) => screenAudioEditService.insertSilence(5)} className={"btn btn-link " + classes.speeds} >
                                <span>5.00 s</span>
                            </button>
                        </div>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        </div>
    </>
}

export default WaveEditorControls;