import { Children, ReactElement } from "react";
import { useI18n } from "src/utils/lni18n";

const ChangedColorLegend = () => {

    const { languageService: t } = useI18n();

    return <ChangedColorLegendWrap>
        <div className="mr-3 d-flex">
            <div className="bg-primary mr-1">&nbsp;</div> {t.getText("new")}
        </div>

        <div className="mr-3 d-flex">
            <div className="bg-warning mr-1">&nbsp;</div> {t.getText("changed")}
        </div>

        <div className="mr-3 d-flex">
            <div className="bg-primary-danger-mix-vertical mr-1">&nbsp;</div> {t.getText("moved")}
        </div>

        <div className="mr-3 d-flex">
            <div className="bg-danger mr-1">&nbsp;</div> {t.getText("deleted")}
        </div>
    </ChangedColorLegendWrap>
}

export default ChangedColorLegend;

export const ChangedColorLegendWrap = (props: { children?: ReactElement | ReactElement[] }) => {

    return <div className="d-flex flex-wrap mb-3">
        {props.children || <div>&nbsp;</div>}
    </div>


}